<!-- eslint-disable vue/no-v-html -->
<template>
	<w-dialog v-model="dialog" :loading="loading" max-width="50vw" :title="$t('quickfolders.create_document')" @close="$emit('close')">
		<w-layout column fill-height>
			<w-flex v-if="folders.length" shrink>
				<w-layout row wrap align-center>
					<w-flex shrink v-html="infoText" />
					<w-flex shrink ml-2>
						<v-autocomplete v-model="selectedFolderId" color="primary" :items="folders" item-text="name" item-value="id" />
					</w-flex>
				</w-layout>
			</w-flex>
			<w-flex shrink style="margin-top: 10px">
				<w-layout column>
					<DropZone v-model="files" multiple />
					<FileList v-model="files" />
				</w-layout>
			</w-flex>
		</w-layout>
		<template v-slot:actions>
			<w-layout row justify-end align-center>
				<w-btn :disabled="!files.length && !selectedFolderId" flat @click="uploadFiles">{{ $t('actions.upload') }}</w-btn>
			</w-layout>
		</template>
	</w-dialog>
</template>

<script>
import QuickFoldersManagerModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersManagerModuleGuard'

export default {
	name: 'QuickFoldersUploader',
	components: {
		DropZone: () => ({
			component: import('@/components/Commons/DropZone')
		}),
		FileList: () => ({
			component: import('@/components/Commons/FileList')
		})
	},
	mixins: [QuickFoldersManagerModuleGuard],
	props: {
		callback: {
			required: false,
			type: Function,
			default: null
		},
		value: {
			required: true,
			type: Object
		},
		origin: {
			required: false,
			type: String,
			default: null
		}
	},
	data: function () {
		return {
			dialog: true,
			files: [],
			folders: [],
			loading: false,
			selectedFolderId: null
		}
	},
	computed: {
		infoText: function () {
			let result = ''
			if (this.containsMonths) {
				result = 'quickfolders.contains_months'
			} else if (this.folders.length) {
				result = 'quickfolders.no_month_but_children'
			} else {
				result = 'quickfolders.no_child'
			}
			return this.$t(result, { name: this.value.name })
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		}
	},
	watch: {
		'value.id': {
			handler: 'onQuickFolderChanged',
			immediate: true
		}
	},
	methods: {
		getFolderDates: function (folderId) {
			return this.service
				.listFolderFlags(this.model.folder.vendor_id, folderId, {
					weight: 1
				})
				.then(flags => {
					const result = {}

					flags.forEach(flag => {
						if (flag.name === 'end_date') {
							result.end = flag.value
						} else if (flag.name === 'start_date') {
							result.start = flag.value
						}
					})

					return result
				})
		},
		listFolders: function (folderId) {
			return this.service.listFolders(this.model.folder.vendor_id, folderId)
		},
		onQuickFolderChanged: function () {
			this.loading = true

			return new Promise(resolve => {
				this.folders.clear()
				const result = this.listFolders(this.value.folder.id)
				resolve(result)
			})
				.then(folders => {
					folders.forEach(folder => {
						this.folders.push(folder)
					})
					return this.selectDefaultFolder()
				})
				.finally(() => {
					this.loading = false
				})
		},
		selectDefaultFolder: function () {
			return new Promise(resolve => {
				this.unselectFolder()

				if (this.folders.length === 0) {
					return resolve()
				}

				const promises = []

				this.folders.forEach(folder => {
					const promise = this.getFolderDates(folder.id).then(({ end, start }) => {
						folder.end = end
						folder.start = start
					})
					promises.push(promise)
				})

				const result = Promise.allSettled(promises)
				resolve(result)
			}).then(() => {
				const time = new Date().getTime()

				const defaultFolder = this.folders.find(folder => {
					if (!folder.hasOwnProperty('end') || !folder.hasOwnProperty('start')) {
						return false
					}

					const end = new Date(folder.end)
					const start = new Date(folder.start)

					return end.getTime() >= time && start.getTime() <= time
				})

				if (defaultFolder) {
					this.selectFolder(defaultFolder.id)
				}
			})
		},
		/**
		 * @param {number} folderId
		 *
		 * @return {Promise<void>}
		 */
		selectFolder: function (folderId) {
			return new Promise(resolve => {
				if (this.selectedFolderId !== folderId) {
					this.selectedFolderId = folderId
				}
				resolve()
			})
		},
		/**
		 * @return {Promise<void>}
		 */
		unselectFolder: function () {
			return new Promise(resolve => {
				if (this.selectedFolderId !== null) {
					this.selectedFolderId = null
				}
				resolve()
			})
		},
		uploadFiles: function () {
			const destinationId = this.selectedFolderId ? this.selectedFolderId : this.value.folder.id
			// TODO: migrate to DocumentManagerService
			let params = this.origin ? { origin: this.origin } : {}
			this.service.uploadItems(this.model.folder.vendor_id, this.files, destinationId, true, params).then(res => {
				if (this.callback) {
					this.callback(res)
				}
			})
			this.$emit('close')
		}
	}
}
</script>
